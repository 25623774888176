<template>
  <b-card no-body>
    <table-header :per-page-options="perPageOptions">
      <template #button>
        <v-select v-model="status"
                  style="width: 250px"
                  :clearable="true"
                  :options="['TODOS...','RESERVADO','SOLICITADO','NO SE PRESENTO','REPROGRAMADO','CANCELADO','ATENDIDO']" placeholder="TODOS"
                  @input="refetchData"
        />
        <b-form-datepicker
          today-button
          reset-button
          close-button
          v-model="starttime"
          label-no-date-selected="Fecha"
          locale="es"
          :show-decade-nav="true"
          :date-format-options="{day: 'numeric', month: 'numeric', year: 'numeric' }"
          @input="refetchData"
          class="ml-1"
          style="width: 150px"
        />
<!--        PRINT IMPRIMIR LISTA PDF-->
        <b-button variant="primary" class="btn-icon ml-1" @click="downloadPdf">
          <feather-icon icon="PrinterIcon" />
        </b-button>

      </template>
    </table-header>

    <b-table
      ref="refTable"
      :items="fetchItems"
      :fields="tableColumns"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      empty-text="No se encontraron resultados"
      responsive
      primary-key="id"
      class="position-relative"
    >
      <template #cell(show_details)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Oculta' : 'Ver' }}</span>
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Fecha Nacimiento : </strong>{{ row.item.treatment.patient.birth_date | getDate }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Email : </strong>{{ row.item.treatment.patient.email }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Género : </strong>{{ row.item.treatment.patient.gender }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Telefono : </strong>{{ row.item.treatment.patient.phone_number }} - {{ row.item.treatment.patient.insured[0].reference_phone_number }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Zona y Dirección : </strong>{{ row.item.treatment.patient.insured[0].region }} - {{ row.item.treatment.patient.insured[0].address_zone }} - {{ row.item.treatment.patient.insured[0].address }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Empleador : </strong>{{ row.item.treatment.patient.insured[0].employer_code }} - {{ row.item.treatment.patient.insured[0].employer_name }}
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>último usuario que atendio : </strong><span v-if="row.item.medic">{{ row.item.medic.fullname }}</span>
            </b-col>
          </b-row>
          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>

      <template #cell(actions)="data">
        <ShowHistoryButton :item="data.item" />
      </template>

      <template #cell(date_reservation)="data">
        {{ data.value| formatDate }}
      </template>

      <template #cell(start_time)="data">
        <strong>{{ data.value| formatDate }}</strong>
      </template>

      <template #cell(updated_at)="data">
        <span v-if="data.item.status.name === 'ATENDIDO'">{{ data.item.updated_at | formatDate }}</span>
      </template>

      <template #cell(status.name)="data">
        <b-badge pill :variant="`light-${statusVariant[data.value]}`">
          <small>{{ data.value.toLowerCase() }}</small>
        </b-badge>
      </template>
    </b-table>

    <table-pagination :total-rows="totalRows" :per-page="perPage" />
  </b-card>
</template>

<script>
// TODO: Mostrar edad, motivo consulta

import useList from '@/custom/libs/useList'

import TableHeader from '@/custom/components/Tables/TableHeader'

import TablePagination from '@/custom/components/Tables/TablePagination'
import { AppointmentResource } from '@/network/lib/appointment'
import { getDate, getTime, formatDate } from '@/custom/filters'
import ShowHistoryButton from './ActionButtons'
import { ref } from '@vue/composition-api/dist/vue-composition-api'

export default {
  name: 'DoctorAppointmentList',
  components: {
    TableHeader,
    TablePagination,
    ShowHistoryButton
  },
  filters: {
    getDate,
    getTime,
    formatDate
  },
  setup() {
    let {
      refTable,
      perPage,
      perPageOptions,
      currentPage,
      totalRows,
      searchQuery,
      sortBy,
      isSortDirDesc,
      route,
      deleteResource,
      refetchData
    } = useList()
    const status = ref('RESERVADO')
    const starttime = ref('')
    const fetchItems = async () => {
      if (status.value === 'TODOS...') {
        status.value = ''
      }
      const sortOption = 'sortBy' + (isSortDirDesc.value ? 'Asc' : 'Desc')
      const { data } = await AppointmentResource.getAll({
        scope: `search:${searchQuery.value},status:${status.value},starttime:${starttime.value}`,
        [sortOption]: 'start_time',
        limit: perPage.value,
        page: currentPage.value,
        ['start_time']: sortBy.value,
        include: 'center;unit;specialty;status;treatment.patient.insured;calendar.attentionType;medic'
      })

      totalRows.value = data.total_data

      console.log('listadoxxxx')
      console.log(data)

      return data.rows
    }

    const downloadPdf = async () => {
      const sortOption = 'sortBy' + (isSortDirDesc.value ? 'Asc' : 'Desc')
      const response = await AppointmentResource.download({
        scope: `search:${searchQuery.value},status:${status.value},starttime:${starttime.value}`,
        [sortOption]: 'start_time',
        include: 'status;treatment.patient;center;unit.specialty;unit.serviceHour;unit.staff.roles;attention',
        date: starttime.value
      })

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = URL.createObjectURL(blob)
      window.open(url)
    }


    const statusVariant = {
      'RESERVADO': 'success',
      'SOLICITADO': 'warning',
      'NO SE PRESENTO': 'secondary',
      'CANCELADO': 'danger',
      'ATENDIDO': 'info',
      'REPROGRAMADO': 'secondary',
    }

    const tableColumns = [
      { key: 'show_details', label: 'Ver', thStyle: { width: '10px' }},
      { key: 'actions', label: 'Acciones', thStyle: { width: '100px' } },
      { key: 'specialty.name', label: 'Especialidad', sortable: false },
      { key: 'center.name', label: 'Centro', sortable: false },
      { key: 'unit.name', label: 'Consultorio', sortable: false },
      { key: 'treatment.patient.fullname', label: 'Asegurado', sortable: false },
      { key: 'treatment.patient.ci', label: 'Carnet', sortable: false },
      { key: 'treatment.patient.registration_code', label: 'Matricula', sortable: false },
      { key: 'date_reservation', label: 'Fecha de Solicitud', sortable: false },
      { key: 'start_time', label: 'Fecha Cita Medica', sortable: false },
      { key: 'updated_at', label: 'Fecha Atención', sortable: false },
      { key: 'status.name', label: 'Estado', sortable: false },
      { key: 'treatment.comment', label: 'Motivo Consulta', sortable: false },
      { key: 'calendar.attention_type.name', label: 'Tipo Consulta', sortable: false },
    ]

    return {
      refTable,
      perPage,
      perPageOptions,
      currentPage,
      totalRows,
      searchQuery,
      tableColumns,
      sortBy,
      isSortDirDesc,
      statusVariant,
      status,
      starttime,
      fetchItems,
      downloadPdf,
      deleteResource,
      refetchData
    }
  }
}
</script>

<style scoped></style>
